import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { bro17landsalldataoutput } from '../actions'
import { withAuthenticator } from 'aws-amplify-react'

class Bro17landsalldataview extends Component {
  constructor(props) {
    super(props);
    console.log("constructor")
    //dasoku
    this.state ={ count:0 ,filterflg: false }
    //this.innerRenderEvents = this.innerRenderEvents.bind(this);
  }
  componentWillMount(){
    console.log("componentWillMount")
    const {params} = this.props.match
    //console.log(params.id.split('-')[0])
    this.props.bro17landsalldataoutput({color:params.id})
    //this.setState({ color:params.id })
    //this.props.bro17landsalldataoutput({color:params.id.split('-')[0]})
    this.setState({ color:params.id.split('-')[0] ,setname:params.id.split('-')[1] })
    console.log(this.props.viewevent)
  } 
  handleColorFilterButton = (header) => {
    if (this.state.filterflg){
      const result=this.state.filteredvalue.filter(data => data.color == header);
      this.setState({ filteredvalue: result })
    } else {
      const result=this.state.getprops.filter(data => data.color == header);
      this.setState({ filteredvalue: result })
    }
    this.setState({ filterflg: true })
  }
  handleCardTypeFilterButton = (header) => {
    if (this.state.filterflg){
      if (header == "other"){
        const result=this.state.filteredvalue.filter(data => data.cardtype != 'クリーチャー' && data.cardtype != 'インスタント');
        this.setState({ filteredvalue: result })
      } else {
        const result=this.state.filteredvalue.filter(data => data.cardtype == header);
        this.setState({ filteredvalue: result })
      }
    } else {
      if (header == "other"){
        const result=this.state.getprops.filter(data => data.cardtype != 'クリーチャー' && data.cardtype != 'インスタント');
        this.setState({ filteredvalue: result })
      } else {
        const result=this.state.getprops.filter(data => data.cardtype == header);
        this.setState({ filteredvalue: result })
      }
    }
    this.setState({ filterflg: true })
  }
  handleRarityFilterButton = (header) => {
    if (this.state.filterflg){
      if (header == 'UC') {
        const result=this.state.filteredvalue.filter(data => data.Rarity == 'U' || data.Rarity == 'C' );
        this.setState({ filteredvalue: result })
      } else if (header == 'MR') {
        const result=this.state.filteredvalue.filter(data => data.Rarity == 'M' || data.Rarity == 'R' );
        this.setState({ filteredvalue: result })
      } else {
        const result=this.state.filteredvalue.filter(data => data.Rarity == header );
        this.setState({ filteredvalue: result })
      }
    } else {
      if (header == 'UC') {
        const result=this.state.getprops.filter(data => data.Rarity == 'U' || data.Rarity == 'C' );
        this.setState({ filteredvalue: result })
      } else if (header == 'MR') {
        const result=this.state.getprops.filter(data => data.Rarity == 'M' || data.Rarity == 'R' );
        this.setState({ filteredvalue: result })
      } else {
        const result=this.state.getprops.filter(data => data.Rarity == header );
        this.setState({ filteredvalue: result })
      }
    }
    this.setState({ filterflg: true })
  }
  handleFilterResetButton = () => {
    this.setState({ filterflg: false })
  }
  handlePlusButton = (header) => {
    if (this.state.filterflg){
      //console.log(header)
      //console.log(this.state.filteredvalue[0][header])
      if (isNaN(Number(this.state.filteredvalue[0][header])) || this.state.filteredvalue[0][header] == '') {
        this.state.filteredvalue.sort(
          function(a,b){
            if (a[header] == "" || a[header] == null){
              return 1
            }
            else if (a[header] < b[header]) {
              return 1;
            } else {
              return -1;
            }
          }
        )
      } else {
        this.state.filteredvalue.sort(
          function(a,b){ 
            return ( b[header] - a[header] ) 
          }
        )
      }
    } else {
      //console.log(header)
      //console.log(this.state.getprops[0][header])
      if (isNaN(Number(this.state.getprops[0][header])) || this.state.getprops[0][header] == '') {
        this.state.getprops.sort(
          function(a,b){
            if (a[header] == "" || a[header] == null){
              return 1
            }
            else if (a[header] < b[header]) {
              return 1;
            } else {
              return -1;
            }
          }
        )
      } else {
        this.state.getprops.sort(
          function(a,b){ 
            return ( b[header] - a[header] ) 
          }
        )
      }
    }
    if (header == "num"){
      this.state.getprops.sort(
        function(a,b){ 
          return ( a[header] - b[header] ) 
        }
      )
    }
    //}
    //for(var i in this.state.getprops){
    //    console.log(this.state.getprops[i])
    //}
    //dasoku
    this.setState({ count: this.state.count + 1})
    console.log(this.state.count)
  }
  innerRenderEvents = (arg) => {
    return _.map(arg,event =>
      <tr key={event.num}>
        <td>{event.num}</td>
        <td><img src={`https://mtg.www.montamanet.com/${ this.state.setname }-image/${event.url2}.png`} width="80" height="130"/></td>
        <td>{event.GIH}</td>
        <td>{event.GIHWR}</td>
        <td>{event.GP}</td>
        <td>{event.GPWR}</td>
        <td>{event.OH}</td>
        <td>{event.OHWR}</td>
        <td>{event.IWD}</td>
        <td>{event.ALSA}</td>
        <td>{event.Picked}</td>
        <td>{event.ATA}</td>
        <td>{event.url2}</td>
        <td>{event.jpname}</td>
        <td>{event.cardtype}</td>
        <td>{event.manavalue}</td>
        <td>{event.powertafness}</td>
        <td>{event.enname}</td>
        <td>{event.color}</td>
        <td>{event.Rarity}</td>
        <td>{event.GD}</td>
        <td>{event.GDWR}</td>
        <td>{event.GNS}</td>
        <td>{event.GNSWR}</td>
      </tr>
      )
  }
  renderEvents(){
    console.log("renderEvents")
    //Only execution setState if "state equal undefined" and "props element is empty."
    if (this.state.getprops === undefined) { 
      if (Object.keys(this.props.viewevent).length) {
        console.log("execution setState")
        this.setState({ getprops: this.props.viewevent })
        console.log(this.state.getprops)
      }
    }
    if (this.state.getprops === undefined) { 
        console.log("props")
        return this.innerRenderEvents(this.props.viewevent)
    } else {
        console.log("state")
        if (this.state.filterflg){
          return this.innerRenderEvents(this.state.filteredvalue)
        } else {
          return this.innerRenderEvents(this.state.getprops)
        }
    }
  }

  render() {
    console.log("render")
    return (
      <div>
          <div>You chose this : { this.state.color }</div>
          <div>蛇足だがUdemyでStateの使い方を思い出せるcount: { this.state.count }</div>
        　<button onClick={this.handleFilterResetButton}>Filter Reset</button>
        　<button onClick={this.handleRarityFilterButton.bind(this,"C")}>C</button>
        　<button onClick={this.handleRarityFilterButton.bind(this,"U")}>U</button>
        　<button onClick={this.handleRarityFilterButton.bind(this,"R")}>R</button>
        　<button onClick={this.handleRarityFilterButton.bind(this,"M")}>M</button>
        　<button onClick={this.handleRarityFilterButton.bind(this,"UC")}>UC</button>
        　<button onClick={this.handleRarityFilterButton.bind(this,"MR")}>MR</button>
        　<button onClick={this.handleColorFilterButton.bind(this,"white")}>White</button>
        　<button onClick={this.handleColorFilterButton.bind(this,"blue")}>Blue</button>
        　<button onClick={this.handleColorFilterButton.bind(this,"black")}>Black</button>
        　<button onClick={this.handleColorFilterButton.bind(this,"red")}>Red</button>
        　<button onClick={this.handleColorFilterButton.bind(this,"green")}>Green</button>
        　<button onClick={this.handleColorFilterButton.bind(this,"multicolor")}>Multicolor</button>
        　<button onClick={this.handleColorFilterButton.bind(this,"artifact")}>Artifact</button>
        　<button onClick={this.handleCardTypeFilterButton.bind(this,"クリーチャー")}>creature</button>
        　<button onClick={this.handleCardTypeFilterButton.bind(this,"インスタント")}>instant</button>
        　<button onClick={this.handleCardTypeFilterButton.bind(this,"other")}>other</button><br />　
          # Seen … そのカードを見た回数
          # ALSA (Average Last Seen At) … 最後に見た手番
          # Picked … ピックされた回数
          # ATA (Average Taken At) … 取った手番
          # GP (Games Played) … メインデッキに投入された試合
          # OH (Games in Opening Hand) … 初手に来た試合
          # GD (Games Drawn) … 試合開始以降に引いた試合
          # GIH (Games in Hand) … 手札に来た試合
          # GND (Games Not Drawn) … 引かなかった試合
          # IWD (Improvement when Drawn) … 引いた時の勝率の上昇値
          ※優先度はGIH,OH,IWD,GP(個人的には「GIH(コントロール)」か「OH(アグロ)」)
        　<table  border="1">
              <tr>
                  <td><button onClick={this.handlePlusButton.bind(this,"num")}>num</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"url1")}>url1</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"GIH")}>GIH</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"GIHWR")}>GIHWR</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"GP")}>GP</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"GPWR")}>GPWR</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"OH")}>OH</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"OHWR")}>OHWR</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"IWD")}>IWD</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"ALSA")}>ALSA</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"Picked")}>Picked</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"ATA")}>ATA</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"url2")}>url2</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"jpname")}>jpname</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"cardtype")}>cardtype</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"manavalue")}>manavalue</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"powertafness")}>powertafness</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"enname")}>enname</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"color")}>color</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"Rarity")}>Rarity</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"GD")}>GD</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"GDWR")}>GDWR</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"GNS")}>GNS</button></td>
                  <td><button onClick={this.handlePlusButton.bind(this,"GNSWR")}>GNSWR</button></td>
              </tr>
              {this.renderEvents()} 
          </table>
          <div># Seen … そのカードを見た回数</div>
          <div># ALSA (Average Last Seen At) … 最後に見た手番</div>
          <div># Picked … ピックされた回数</div>
          <div># ATA (Average Taken At) … 取った手番</div>
          <div># GP (Games Played) … メインデッキに投入された試合</div>
          <div># OH (Games in Opening Hand) … 初手に来た試合</div>
          <div># GD (Games Drawn) … 試合開始以降に引いた試合</div>
          <div># GIH (Games in Hand) … 手札に来た試合</div>
          <div># GND (Games Not Drawn) … 引かなかった試合</div>
          <div>③# IWD (Improvement when Drawn) … 引いた時の勝率の上昇値</div>
          <div>まとめ。※「GD」と「GNS」は使わない。「GIH(コントロール)」か「OH(アグロ)」かでソートする。IWDはあくまでボムレアを探す指標。※その他の指標は参考程度</div>
          <div>①GIH:そのカードを引いた時の勝率なので基本にしやすい(ただし長期戦向きデッキで強いカード)の勝率が高く出やすくなる→逆に数値が小さいのは長期戦弱くなるのでGIHでわかりやすい</div>
          <div>②OH:初手での勝率がわかるため→アグロで強いカードがわかりやすい(評価するときに便利)</div>
          <div>GP WR:そのカードが入っているデッキでの勝率(そのカードを引いたかは寒けない)→GIHのデータの不純要素をなくして勝利地を見れるが、「アーキの勝率」の影響を受けやすい</div>
          <div>④GP:使用率　多い＝いつでも使える　少ない＝限られたデッキで使われている→GP WRが高くてもGPが低い場合がある(この場合は、使えるデッキが限られていたり、上手い人だけ使っていたりする)</div>
          <div>ATA:ピック手順　そのカードの17landsユーザーでの人気がわかる</div>
          <div>ALSA:平均で何パック目まで残っているか　世間的人気がわかる(高めであるなら一周するという事)</div>
          <div>蛇足:ALSA, ATA, GP勝率, GIH勝率あたりは重要</div>
      </div>
      )
  }
}


const mapStateToProps = state => ({ viewevent: state.viewevent}) 
const mapDispatchToProps = ({ bro17landsalldataoutput })  
export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(Bro17landsalldataview))

