import axios from 'axios'
import { Auth } from 'aws-amplify';
export const SAMPLE_LIST = 'SAMPLE_LIST'
export const PACK_OPEN = 'PACK_OPEN'
export const CARD_LIST = 'CARD_LIST'
export const VIEW_LIST = 'VIEW_LIST'
export const CARDBUY_LIST = 'CARDBUY_LIST'
export const GET_DASHBOARD = 'GET_DASHBOARD'
export const ELSE   = 'ELSE'

//Amplify.configure({
//  Auth: {
//    region: 'ap-northeast-1',
//    userPoolId: 'ap-northeast-1_Z0LPJaRE0',
//    userPoolWebClientId: 'k94nj2sm9f2i6a41911t6blse',
//  },
//});

//export const sampleoutput = () => async dispatch => { 
//  const response = await axios.get("https://hr38y9fqyj.execute-api.ap-northeast-1.amazonaws.com/mtgbar") 
//  if (response.data.body){
//      dispatch({ type: SAMPLE_LIST ,response}) 
//  }else{
//      dispatch({ type: ELSE})
//  }
//}

// this is for all-packlist and all-cardlist
export const sampleoutput = () => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  //const response = await axios.get("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/packlist",myInit) 
  const response = await axios.get("https://amplify.www.montamanet.com/stage/packlist",myInit) 
  dispatch({ type: SAMPLE_LIST ,response}) 
}

export const openpackoutput = (values) => async dispatch => {
  //const values = {set_name:'Ravnica Allegiance'}
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  //const response = await axios.post("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/packlist",values,myInit) 
  const response = await axios.post("https://amplify.www.montamanet.com/stage/packlist",values,myInit) 
  //console.log("wwwww")
  //console.log(response)
  //console.log("wwwww")
  dispatch({ type: PACK_OPEN, response })
}
export const cardlistoutput = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  //const response = await axios.post("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/cardlist",values,myInit) 
  const response = await axios.post("https://amplify.www.montamanet.com/stage/cardlist",values,myInit) 
  //console.log("yyyyy")
  //console.log(response)
  //console.log("yyyyy")
  dispatch({ type: CARD_LIST ,response}) 
}
//export const getpackopen = (values) => async dispatch => {
  //const values = {set_name:'Ravnica Allegiance'}
//  const response = await axios.post("https://amplify.www.montamanet.com/stage/packlist",values) 
  //console.log(response)
//  dispatch({ type: PACK_OPEN, response })
//}
export const accountbalanceoutput = () => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  const values = {username:user.username}
  //const response = await axios.get("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/accountbalance-view",myInit)
  const response = await axios.post("https://amplify.www.montamanet.com/stage/accountbalance-view",values,myInit)
  dispatch({ type: VIEW_LIST ,response}) 
}

export const bro17landsalldataoutput = (value) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  console.log(value)
  //const response = await axios.get("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/bro-17lands-alldata?color=" + value,myInit)
  //const response = await axios.post("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/bro-17lands-alldata",value,myInit) 
  const response = await axios.post("https://amplify.www.montamanet.com/stage/bro-17lands-alldata",value,myInit)
  console.log(response)
  dispatch({ type: VIEW_LIST ,response}) 
}


export const bro17landstrophyoutput = (value) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  console.log(value)
  //const response = await axios.post("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/bro-17lands-trophy",value,myInit)
  const response = await axios.post("https://amplify.www.montamanet.com/stage/bro-17lands-trophy",value,myInit) 
  console.log(response)
  dispatch({ type: VIEW_LIST ,response}) 
}




//export const m20hareruya1output = (values) => async dispatch => { 
//  const response = await axios.get("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/m20hareruya1view") 
//  dispatch({ type: VIEW_LIST ,response}) 
//}

//export const m20user10output = (values) => async dispatch => { 
//  const response = await axios.get("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/m20user10view") 
//  dispatch({ type: VIEW_LIST ,response}) 
//}

export const userstockoutput = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  if (values.username === 'hareruya1') {
      values.username = 'hareruya1' + '_' + user.username.split("_")[1]
  }else{
      values.username = user.username
  }
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  //const response = await axios.post("https://ffbjqna1d8.execute-api.ap-northeast-1.amazonaws.com/stage/userstock-view",values,myInit) 
  const response = await axios.post("https://amplify.www.montamanet.com/stage/userstock-view",values,myInit) 
  dispatch({ type: VIEW_LIST ,response}) 
}

export const cardbuyeoutput = (values) => async dispatch => { 
  console.log("ttttt")
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["buyorsell"] = "buy"
  values["username"] = user.username
  values["storename"] = 'hareruya1' + '_' + user.username.split("_")[1]
  //const response = await axios.post("https://zjev05gn3f.execute-api.ap-northeast-1.amazonaws.com/singlebuy",values,myInit) 
  const response = await axios.post("https://amplify.www.montamanet.com/singlebuy",values,myInit) 
  dispatch({ type: CARDBUY_LIST ,response}) 
}

export const cardselleoutput = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["buyorsell"] = "sell"
  values["username"] = 'hareruya1' + '_' + user.username.split("_")[1]
  values["storename"] = user.username
  //const response = await axios.post("https://zjev05gn3f.execute-api.ap-northeast-1.amazonaws.com/singlebuy",values,myInit) 
  const response = await axios.post("https://amplify.www.montamanet.com/singlebuy",values,myInit) 
  dispatch({ type: CARDBUY_LIST ,response}) 
}
export const packbuyoutput = (values) => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  values["username"] = user.username
  //const response = await axios.post("https://al54zy8ayf.execute-api.ap-northeast-1.amazonaws.com/packbuy",values,myInit) 
  const response = await axios.post("https://amplify.www.montamanet.com/packbuy",values,myInit) 
  //console.log("zzzzzz")
  //console.log(response)
  //console.log("zzzzzz")
  dispatch({ type: PACK_OPEN ,response}) 
}

export const getquicksighturloutput = () => async dispatch => { 
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const myInit = {
    headers: {
      Authorization: token,
    },
  };
  //const response = await axios.get("https://nm3tughxv8.execute-api.ap-northeast-1.amazonaws.com/dev",myInit) 
  const response = await axios.get("https://amplify.www.montamanet.com/dev",myInit) 
  console.log("kkkkkk")
  //console.log(response)
  //console.log("kkkkkk")
  dispatch({ type: GET_DASHBOARD ,response}) 
}

