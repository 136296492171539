import { Link } from 'react-router-dom'
import { withAuthenticator } from 'aws-amplify-react'
//import config from './aws-exports'
//Amplify.configure(config)
import { Analytics } from 'aws-amplify'
//import Amplify, { Analytics } from 'aws-amplify'
//Amplify.configure({
//  Analytics: {
//      disabled: false,
//      autoSessionRecord: true,
//      AWSPinpoint: {
//          appId: '040cca9ea87447e9ba450cfd91e5f3e4',
//          region: 'ap-northeast-1',
//          bufferSize: 1000,
//          flushInterval: 5000,
//          flushSize: 100,
//          resendLimit: 5
//      }
//  },
//});


function App() {
  //Put data to Amazon Pinpoint.
  //Analytics.record({name: "show_home"});
  return (
    <div className="App">
        <h1><Link className="header" to="/Sample/0">PackList</Link></h1>
        <h1><Link className="header" to="/Sample2/0">CardList</Link></h1>
        <h1><Link className="header" to="/Accountbalanceview">残金確認</Link></h1>
        <h1><Link className="header" to="/Broview">兄弟戦争</Link></h1>
        <h1><Link className="header" to="/Oneview">完全なる統一</Link></h1>
        <h1><Link className="header" to="/Momview">機械兵団の進軍</Link></h1>
        <h1><Link className="header" to="/CardBuyPre">カード購入</Link></h1>
        <h1><Link className="header" to="/CardSellPre">カード売却</Link></h1>
        <h1><Link className="header" to="/PackBuy">パック購入</Link></h1>
        <h1><Link className="header" to="/QuicksightView">Quicksight</Link></h1>
<table border="3">
<tr>
	<th>Name</th><th>Date</th><th>Title</th>
</tr>
<tr><td>can</td><td>you</td><td>see
</td></tr><tr><td>me  </td><td>?</td><td>? 
</td></tr></table>
    </div>
  );
}

export default withAuthenticator(App);
