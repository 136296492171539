import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { openpackoutput } from '../actions'
import { withAuthenticator } from 'aws-amplify-react'

class OpenPack extends Component {
  componentDidMount(){ 
    const {params} = this.props.match
    //console.log("tttttt")
    //console.log(params.id)
    //console.log("tttttt")
    this.props.openpackoutput({set_name:params.id})
  } 
  renderEvents(){
    return _.map(this.props.openpackevent,event =>
        <img src={`https://mtg.www.montamanet.com/cards/${event.multiverse_id}.png`}/>
    )
  }
  render() {
    return (
      <div>
        {this.renderEvents()} 
      </div>
      )
  }
}
const mapStateToProps = state => ({ openpackevent: state.openpackevent}) 
const mapDispatchToProps = ({ openpackoutput })  
export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(OpenPack))

