//import { combineReducers } from 'redux'
import _ from 'lodash' 
import { reducer as form } from 'redux-form'
import { VIEW_LIST } from '../actions'

export default function viewevent (result = {}, action) { 
    switch (action.type) {
    case "VIEW_LIST":
        //console.log(action.response.data)
        return action.response.data
    default: 
        return result 
    }
}
