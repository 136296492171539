//import { combineReducers } from 'redux'
import _ from 'lodash' 
import { reducer as form } from 'redux-form'
import { SAMPLE_LIST } from '../actions'

export default function sampleevent (result = {}, action) { 
    switch (action.type) {
    case "SAMPLE_LIST":
        console.log("hhh")
        console.log(action.response.data.body)
        console.log(action.response.data.body.length)
        console.log("hhh")
        //console.log(_.mapKeys(JSON.parse(action.response.data.body),'id'))
　      return action.response.data.body
　      //return _.mapKeys(action.response.data,'id')
        //return _.mapKeys(JSON.parse(action.response.data.body),'num')
    default: 
        return result 
    }
}
