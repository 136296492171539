import React from 'react'; 
import ReactDOM from 'react-dom'; 
import { createStore, applyMiddleware } from 'redux' 
import { Provider } from 'react-redux' 
import thunk from 'redux-thunk' 
import { BrowserRouter, Route , Switch } from 'react-router-dom' 
import './index.css'; 
import reducer from './reducers' 
import App from './App';
import Sample from './components/Sample';
import Sample2 from './components/Sample2';
import OpenPack from './components/OpenPack';
import CardList from './components/CardList';
import Accountbalanceview from './components/Accountbalanceview';
import Bro17landsalldataview from './components/Bro17landsalldataview';
import Bro17landstrophyview from './components/Bro17landstrophyview';
import Broview from './components/Broview';
import Oneview from './components/Oneview';
import Momview from './components/Momview';
import M20hareruya1view from './components/M20hareruya1view';
import M20user10view from './components/M20user10view';
import CardBuy from './components/CardBuy';
import CardBuyComp from './components/CardBuyComp';
import CardBuyPre from './components/CardBuyPre';
import CardSell from './components/CardSell';
import CardSellPre from './components/CardSellPre';
import PackBuy from './components/PackBuy';
import PackBuyComp from './components/PackBuyComp';
import QuicksightView from './components/QuicksightView';
import Header from './components/Header';
//import PackList from './components/PackList';
//        <Route exact path='/PackList' component={PackList} />
//        <Route exact path='/PackLimitedEditionAlpha' component={PackLimitedEditionAlpha} />
//import * as serviceWorker from './serviceWorker'; 
import reportWebVitals from './reportWebVitals';

import Amplify from 'aws-amplify'
//import Amplify, { Analytics,Auth } from 'aws-amplify'
//import config from './aws-exports'
//Amplify.configure(config)
Amplify.configure({
  Auth: {
    region: 'ap-northeast-1',
    userPoolId: 'ap-northeast-1_Z0LPJaRE0',
    userPoolWebClientId: 'k94nj2sm9f2i6a41911t6blse',
    identityPoolId: 'ap-northeast-1:8d03ccf0-e278-41a1-a149-096d5bce4b19',
  },
});

 
const store = createStore(reducer,applyMiddleware(thunk)) 

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
        <Header />
        <Route exact path='/' component={App} />
        <Route exact path='/Sample/:id' component={Sample} />
        <Route exact path='/Sample2/:id' component={Sample2} />
        <Route exact path='/OpenPack/:id' component={OpenPack} />
        <Route exact path='/CardList/:id' component={CardList} />
        <Route exact path='/CardList/:id1/:id2' component={CardList} />
        <Route exact path='/Accountbalanceview' component={Accountbalanceview} />
        <Route exact path='/Bro17landsalldataview/:id' component={Bro17landsalldataview} />
        <Route exact path='/Bro17landstrophyview/:id' component={Bro17landstrophyview} />
        <Route exact path='/M20hareruya1view/:id' component={M20hareruya1view} />
        <Route exact path='/M20user10view/:id' component={M20user10view} />
        <Route exact path='/CardBuy/:id' component={CardBuy} />
        <Route exact path='/CardBuyPre' component={CardBuyPre} />
        <Route exact path='/CardBuyComp' component={CardBuyComp} />
        <Route exact path='/CardSell/:id' component={CardSell} />
        <Route exact path='/CardSellPre' component={CardSellPre} />
        <Route exact path='/PackBuy' component={PackBuy} />
        <Route exact path='/PackBuyComp/:id' component={PackBuyComp} />
        <Route exact path='/QuicksightView' component={QuicksightView} />
        <Route exact path='/Broview' component={Broview} />
        <Route exact path='/Oneview' component={Oneview} />
        <Route exact path='/Momview' component={Momview} />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
//serviceWorker.unregister();

