import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { bro17landstrophyoutput } from '../actions'
import { withAuthenticator } from 'aws-amplify-react'

class Bro17landstrophyview extends Component {
  constructor(props) {
    super(props);
    console.log("constructor")
    //dasoku
    this.state ={ count:0 }
  }
  componentWillMount(){
    console.log("componentWillMount")
    const {params} = this.props.match
    this.props.bro17landstrophyoutput({color:params.id})
    this.setState({ color:params.id.split('-')[0] })
    console.log(this.props.viewevent)
  } 
  renderEvents(){
    console.log("renderEvents")
    //Only execution setState if "state equal undefined" and "props element is empty."
    if (this.state.getprops === undefined) { 
      if (Object.keys(this.props.viewevent).length) {
        console.log("execution setState")
        this.setState({ getprops: this.props.viewevent })
        console.log(this.state.getprops)
      }
    }
    if (this.state.getprops === undefined) { 
        console.log("props")
        return _.map(this.props.viewevent,event =>
            <tr key={event.windate}>
                <td>{event.windate}</td>
                <td>{event.win}</td>
                <td>{event.lose}</td>
                <td>{event.tier}</td>
                <td>{event.color}</td>
                <td><a className="header" href={"https://www.17lands.com/"+event.drafturl}>DraftURL</a></td>
                <td><a className="header" href={"https://www.17lands.com/"+event.deckurl}>DeckURL</a></td>
            </tr>
        )
    } else {
        console.log("state")
        return _.map(this.state.getprops,event =>
            <tr key={event.windate}>
                <td>{event.windate}</td>
                <td>{event.win}</td>
                <td>{event.lose}</td>
                <td>{event.tier}</td>
                <td>{event.color}</td>
                <td><a className="header" href={"https://www.17lands.com/"+event.drafturl}>DraftURL</a></td>
                <td><a className="header" href={"https://www.17lands.com/"+event.deckurl}>DeckURL</a></td>
            </tr>
        )
    }
  }

  render() {
    console.log("render")
    return (
      <div>
          <div>You chose this : { this.state.color }</div>
          <div>蛇足だがUdemyでStateの使い方を思い出せるcount: { this.state.count }</div>
          <table  border="1">
              <tr>
                  <td>日付</td>
                  <td>Win</td>
                  <td>Lose</td>
                  <td>Tier</td>
                  <td>Color</td>
                  <td>Drafturl</td>
                  <td>Deckurl</td>
              </tr>
              {this.renderEvents()} 
          </table>
      </div>
      )
  }
}


const mapStateToProps = state => ({ viewevent: state.viewevent}) 
const mapDispatchToProps = ({ bro17landstrophyoutput })  
export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(Bro17landstrophyview))

