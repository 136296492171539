import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { withAuthenticator } from 'aws-amplify-react'

class CardBuyComp extends Component {
  constructor(props) {
    super(props);
//    this.state = {showBox: false,};
  }
  renderEvents(){
    //console.log(this.props.cardbuyevent.body)
    return (
        <div>{this.props.cardbuyevent.body}</div>
      )
  }
  render() {
    return (
      <div>
        {this.renderEvents()} 
      </div>
      )
  }
}

const mapStateToProps = state => ({ cardbuyevent: state.cardbuyevent}) 
export default withAuthenticator(connect(mapStateToProps, null)(CardBuyComp))

