import _ from 'lodash' 
import { reducer as form } from 'redux-form'
import { PACK_OPEN } from '../actions'

export default function openpackevent (result = {}, action) { 
    switch (action.type) {
    case "PACK_OPEN":
        console.log("xxxxxx")
        console.log(_.mapKeys(JSON.parse(action.response.data.body),'num'))
        console.log("xxxxxx")
        return _.mapKeys(JSON.parse(action.response.data.body),'num')
        //return result 
    default: 
        return result 
    }
}

