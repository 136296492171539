//import { combineReducers } from 'redux'
import _ from 'lodash' 
import { reducer as form } from 'redux-form'
import { GET_DASHBOARD } from '../actions'

export default function quicksightevent (result = {}, action) { 
    switch (action.type) {
    case "GET_DASHBOARD":
        //console.log(action.response.data)
        return action.response.data
    default: 
        return result 
    }
}
