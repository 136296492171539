import React, { Component, useState } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { accountbalanceoutput } from '../actions'
import { withAuthenticator } from 'aws-amplify-react'

class Accountbalanceview extends Component {
  constructor(props) {
    super(props);
    console.log("constructor")
    //dasoku
    this.state ={ count:0 }
  }
  componentWillMount(){
    console.log("componentWillMount")
    this.props.accountbalanceoutput()
    console.log(this.props.viewevent)
  } 
  handlePlusButton = (header) => {
    this.state.getprops.sort(
      //this.props.viewevent.sort(
      function(a,b){ 
        return ( b[header] - a[header] ) 
      }
    )     
    //}
    //for(var i in this.state.getprops){
    //    console.log(this.state.getprops[i])
    //}
    //dasoku
    this.setState({ count: this.state.count + 1})
    console.log(this.state.count)
  }
  renderEvents(){
    console.log("renderEvents")
    //Only execution setState if "state equal undefined" and "props element is empty."
    if (this.state.getprops === undefined) { 
      if (Object.keys(this.props.viewevent).length) {
        console.log("execution setState")
        this.setState({ getprops: this.props.viewevent })
        console.log(this.state.getprops)
      }
    }
    if (this.state.getprops === undefined) { 
        console.log("props")
        return _.map(this.props.viewevent,event =>
            <tr key={event.id}>
                <td>{event.username}</td>
                <td>{event.balance}</td>
            </tr>
        )
    } else {
        console.log("state")
        return _.map(this.state.getprops,event =>
            <tr key={event.id}>
                <td>{event.username}</td>
                <td>{event.balance}</td>
            </tr>
        )
    }
  }

  render() {
    console.log("render")
    return (
      <div>
          <div>蛇足だがUdemyでStateの使い方を思い出せるcount: { this.state.count }</div>
          <table  border="1">
              <tr>
                  <td>名前</td>
                  <td><button onClick={this.handlePlusButton.bind(this,"balance")}>残金</button></td>
              </tr>
              {this.renderEvents()} 
          </table>
      </div>
      )
  }
}


const mapStateToProps = state => ({ viewevent: state.viewevent}) 
const mapDispatchToProps = ({ accountbalanceoutput })  
export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(Accountbalanceview))

