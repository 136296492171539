import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { cardbuyeoutput } from '../actions'
import { withAuthenticator } from 'aws-amplify-react'

class CardBuy extends Component {
  constructor(props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
    this.renderField = this.renderField.bind(this)
  }
  renderField(field) {
    const { input, label, type, meta: { touched, error } } = field
    return (
      <div>
        <input {...input} placeholder={label} type={type} value={this.props.match.params.id} />
        {touched && error && <span>{error}</span>}
      </div>
      )
  }
  
  async onSubmit(values) {
    await this.props.cardbuyeoutput(values)
    this.props.history.push('/CardBuyComp')
  }
  
  renderEvents(){
    const { handleSubmit,pristine,submitting } = this.props

    return (
      <div id="list">
          <form onSubmit={handleSubmit(this.onSubmit)}>
            <div><Field label='multiverse_id' name='multiverse_id' type='text' component={this.renderField} /></div>
            <div>
            <input type='submit' value='Submit'/>
            <Link to='/'>Cancel</Link>
            </div>
          </form>
          <img src={`https://mtg.www.montamanet.com/cards/${this.props.match.params.id}.png`}/>
      </div>
    )
  }

  render() {
    return (
      <div>
        購入しますか？？
        {this.renderEvents()} 
      </div>
      )
  }
}

const validate = values => {
  const errors = {}
  if (!values.multiverse_id) errors.multiverse_id = 'Enter a multiverse_id, please.'
  return errors
}
const mapDispatchToProps = ({ cardbuyeoutput })
//export default connect(null, null)(EventsNew)
export default withAuthenticator(connect(null, mapDispatchToProps)(
  reduxForm({ validate, form: 'eventNewForm' })(CardBuy)
))
