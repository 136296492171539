import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { userstockoutput } from '../actions'
import { withAuthenticator } from 'aws-amplify-react'

class M20hareruya1view extends Component {
  constructor(props) {
    super(props);
//    this.state = {showBox: false,};
  }
  componentDidMount(){ 
    const {params} = this.props.match
    this.props.userstockoutput({set_name:params.id,username:"hareruya1"})
  } 
  renderEvents(){
    //console.log(this.props.viewevent)
    return _.map(this.props.viewevent,event =>
        <table  border="1">
            <tr key={event.id}>
                <td><Link className="header" to={`/CardBuy/${event.multiverse_id}`}>{event.multiverse_id}</Link></td>
                <td>{event.cardname}</td>
                <td>{event.rarity}</td>
                <td>{event.stock}</td>
                <td>{event.marketprice}</td>
            </tr>
        </table>
      )
  }
  render() {
    return (
      <div>
        {this.renderEvents()} 
      </div>
      )
  }
}

const mapStateToProps = state => ({ viewevent: state.viewevent}) 
const mapDispatchToProps = ({ userstockoutput })  
export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(M20hareruya1view))

