import { combineReducers } from 'redux' 
import { reducer as form } from 'redux-form'  
import sampleevent from './sampleevent'
import openpackevent from './openpackevent'
import cardlistevent from './cardlistevent'
import viewevent from './viewevent'
import cardbuyevent from './cardbuyevent'
import quicksightevent from './quicksightevent'

export default combineReducers({sampleevent,openpackevent,cardlistevent,viewevent,cardbuyevent,quicksightevent, form }) 
