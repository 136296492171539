import React, { Component } from 'react';
import { Link } from 'react-router-dom'
//import { withAuthenticator } from 'aws-amplify-react'

class Broview extends Component {
  render(){
    return (
      <div>
        <h1>AllData</h1>
        <h2>ALL</h2>
        　<Link className="header" to="/Bro17landsalldataview/ALL-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/ALL-bro">Trophy</Link>
        <h2>WU (アグロ・兵士・飛行・横並び) 2 </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585114.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/WU-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/WU-bro">Trophy</Link>
        <h2>WB (アグロ・3以下・横並び) 8(使用NG) </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585101.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/WB-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/WB-bro">Trophy</Link>
        <h2>WR (アグロ・横並び) 1 </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585098.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/WR-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/WR-bro">Trophy</Link>
        <h2>WG (ミッドレンジ・緑起点のアーティファクト+デカブツ) 5 </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585113.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/WG-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/WG-bro">Trophy</Link>
        <h2>UB (コントロール・2枚以上) 9(使用NG) </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585097.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/UB-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/UB-bro">Trophy</Link>
        <h2>UR (アグロ・スペル) 3 </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585110.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/UR-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/UR-bro">Trophy</Link>
        <h2>UG (コントロール・ランプ(コントロールしつつパワーストーン増やしてデカブツ)) 10(使用NG) </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585095.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/UG-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/UG-bro">Trophy</Link>
        <h2>BR (ミッドレンジ・サクリ) 7 </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585102.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/BR-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/BR-bro">Trophy</Link>
        <h2>BG (コントロール・除去・緑起点のアーティファクト+デカブツ(緑赤に近い)・切削) 5 </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585108.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/BG-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/BG-bro">Trophy</Link>
        <h2>RG (ミッドレンジ・緑起点のパワーストーン+デカブツ[緑白に近い]) 4 </h2>
        <img src="https://mtg.www.montamanet.com/bro-image/585094.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/RG-bro">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/RG-bro">Trophy</Link>
      </div>
    )
  }
}

export default Broview;
//export default withAuthenticator(Header);