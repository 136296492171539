import { Link } from 'react-router-dom'
import { withAuthenticator } from 'aws-amplify-react'

function CardSellPre() {
  return (
    <div className="App">
        売却するカードのセットを選択してください<br />
        <Link className="header" to="/M20user10view/Core Set 2020"><img src={`https://mtg.www.montamanet.com/packs/CoreSet2020.jpg`} width="200" height="400"/></Link>
        <Link className="header" to="/M20user10view/Throne of Eldraine"><img src={`https://mtg.www.montamanet.com/packs/ThroneofEldraine.jpg`} width="200" height="400"/></Link>
        <Link className="header" to="/M20user10view/Theros Beyond Death"><img src={`https://mtg.www.montamanet.com/packs/TherosBeyondDeath.jpg`} width="200" height="400"/></Link>
        <Link className="header" to="/M20user10view/Ikoria: Lair of Behemoths"><img src={`https://mtg.www.montamanet.com/packs/IkoriaLairofBehemoths.jpg`} width="200" height="400"/></Link>
        <Link className="header" to="/M20user10view/Core Set 2021"><img src={`https://mtg.www.montamanet.com/packs/CoreSet2021.jpg`} width="200" height="400"/></Link>
        <Link className="header" to="/M20user10view/Zendikar Rising"><img src={`https://mtg.www.montamanet.com/packs/ZendikarRising.jpg`} width="200" height="400"/></Link>
        <Link className="header" to="/M20user10view/Kaldheim"><img src={`https://mtg.www.montamanet.com/packs/Kaldheim.jpg`} width="200" height="400"/></Link>
        <Link className="header" to="/M20user10view/Strixhaven: School of Mages"><img src={`https://mtg.www.montamanet.com/packs/StrixhavenSchoolofMages.jpg`} width="200" height="400"/></Link>
    </div>
  );
}

export default withAuthenticator(CardSellPre);
