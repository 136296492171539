//import { combineReducers } from 'redux'
import _ from 'lodash' 
import { reducer as form } from 'redux-form'
import { CARDBUY_LIST } from '../actions'

export default function cardbuyevent (result = {}, action) { 
    switch (action.type) {
    case "CARDBUY_LIST":
        //console.log(action.response.data)
        return action.response.data
    default: 
        return result 
    }
}
