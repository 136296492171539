import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { getquicksighturloutput } from '../actions'
import { withAuthenticator } from 'aws-amplify-react'
import { embedDashboard } from 'amazon-quicksight-embedding-sdk';


class QuicksightView extends Component {
  constructor(props) {
    //console.log("0000000")
    super(props);
    this.state = {
        loader: true
    };
//    this.state = {showBox: false,};

  }
  componentDidMount(){
    //console.log("123")
    this.getQuickSightDashboardEmbedURL();
  } 
  
  getQuickSightDashboardEmbedURL = async () => {
    //console.log("789")
    await this.props.getquicksighturloutput()
    //console.log(this.props.quicksightevent)
    //console.log(this.props.quicksightevent.EmbedUrl)
    const options = {
        url: this.props.quicksightevent.EmbedUrl,
        container: document.getElementById("dashboardContainer"),
        scrolling: "No",
        height: "1100px",
        width: "1650px",
        footerPaddingEnabled: true,
    };
    embedDashboard(options);
    this.setState({ loader: false });
  }
  render() {
    //console.log("456")
    return (
        <div>
            { this.state.loader && <div> Now Loading... </div> }
            <div id="dashboardContainer"></div>
        </div>
      )
  }
}

const mapStateToProps = state => ({ quicksightevent: state.quicksightevent}) 
const mapDispatchToProps = ({ getquicksighturloutput })  
export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(QuicksightView))

