import _ from 'lodash' 
import { reducer as form } from 'redux-form'
import { CARD_LIST } from '../actions'

export default function cardlistevent (result = {}, action) { 
    switch (action.type) {
    case "CARD_LIST":
        console.log("xxxxxx")
        console.log(action.response.data.body)
        console.log("xxxxxx")
　      return action.response.data.body
        //return result 
    default: 
        return result 
    }
}

