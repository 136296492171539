import React, { Component } from 'react';
import { Link } from 'react-router-dom'
//import { withAuthenticator } from 'aws-amplify-react'

class Oneview extends Component {
  render(){
    return (
      <div>
        <h1>AllData</h1>
        <h2>ALL</h2>
        　<Link className="header" to="/Bro17landsalldataview/ALL-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/ALL-one">Trophy</Link>
         <br /><br />
         白・・・アーティファクト参照・堕落・装備・毒性<br />
         青・・・アーティファクト参照・増殖・油(スペル)・装備<br />
         黒・・・堕落・増殖・油・毒性<br />
         赤・・・装備・油(スペル)<br />
         緑・・・毒性・増殖・油<br />
         ※白主軸でok(それ以外で唯一「赤緑」だけok)<br />
         ※青が使用NG<br />
        <h2>WU (アーティファクト参照) 7(使用NG) </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_440b1e0f4e.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/WU-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/WU-one">Trophy</Link>
        <h2>WB (堕落) 2 </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_1c918d0076.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/WB-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/WB-one">Trophy</Link>
        <h2>WR (装備) 4 </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_db6b931b40.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/WR-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/WR-one">Trophy</Link>
        <h2>WG (毒性) 3 </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_2639db2f6d.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/WG-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/WG-one">Trophy</Link>
        <h2>UB (増殖) 10(使用NG) </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_b6eb7d3d7f.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/UB-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/UB-one">Trophy</Link>
        <h2>UR (油スペル) 9(使用NG) </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_852626db4b.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/UR-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/UR-one">Trophy</Link>
        <h2>UG (増殖) 5 </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_361ff78466.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/UG-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/UG-one">Trophy</Link>
        <h2>BR (油) 6 </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_df21947a26.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/BR-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/BR-one">Trophy</Link>
        <h2>BG (毒性) 8 </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_cd82e26d63.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/BG-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/BG-one">Trophy</Link>
        <h2>RG (油) 1 </h2>
        <img src="https://mtg.www.montamanet.com/one-image/jp_fa10f7adf9.png" width="160" height="240"/>
        　<Link className="header" to="/Bro17landsalldataview/RG-one">Data</Link>
        　<Link className="header" to="/Bro17landstrophyview/RG-one">Trophy</Link>
      </div>
    )
  }
}

export default Oneview;
//export default withAuthenticator(Header);