import React, { Component } from 'react';
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import _ from 'lodash' 
import { Link } from 'react-router-dom'
import { sampleoutput } from '../actions'
import { withAuthenticator } from 'aws-amplify-react'

class Sample2 extends Component {
  constructor(props) {
    super(props);
//    this.state = {showBox: false,};
  }
  componentDidMount(){ 
    this.props.sampleoutput()
  } 
  renderEvents(){
    const slice_num = 30
    const {params} = this.props.match
    //console.log("qqqqqqq")
    //console.log(params)
    //console.log("qqqqqqq")
    const slice_array = Array.prototype.slice.call(this.props.sampleevent,params.id * slice_num,params.id * slice_num + slice_num)
    return _.map(slice_array,event =>
        <Link className="header" to={`/CardList/${event.set_name_long}/0`}><img src={`https://mtg.www.montamanet.com/packs/${event.set_name_short}.jpg`} width="200" height="400"/></Link>
    )
  }
  renderEvents2(){
    const slice_num = 30
    const repeat_num = Math.ceil(this.props.sampleevent.length / slice_num)
    const items = [];
    for (let i = 0; i < repeat_num; i++) {
      items.push(
        <span> <Link className="header" to={`/Sample2/${i}`}>{i}</Link> </span>
      )
    }
    return (
      <div id="list">{items}</div>
    )
  }
  render() {
    //console.log(this.props.sampleevent.length)
    return (
      <div>
        {this.props.sampleevent.length}
        <br />
        閲覧したいエキスパンションを指定してください
        {this.renderEvents2()}
        <br />
        {this.renderEvents()}
        Can you watch me?
      </div>
      )
  }
}

const mapStateToProps = state => ({ sampleevent: state.sampleevent}) 
const mapDispatchToProps = ({ sampleoutput })  
export default withAuthenticator(connect(mapStateToProps, mapDispatchToProps)(Sample2))

