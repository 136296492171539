import React, { Component } from 'react';
import { Link } from 'react-router-dom'
//import { withAuthenticator } from 'aws-amplify-react'

class Header extends Component {
  render(){
    return (
      <div>
        <Link className="header" to='/'>Top</Link>
        　<Link className="header" to='/Sample/0'>PackList</Link>
        　<Link className="header" to='/Sample2/0'>CardList</Link>
        　<Link className="header" to="/Accountbalanceview">残金確認</Link>
        　<Link className="header" to="/Broview">兄弟戦争</Link>
        　<Link className="header" to="/Oneview">完全なる統一</Link>
        　<Link className="header" to="/Momview">機械兵団の進軍</Link>
        　<Link className="header" to="/CardBuyPre">購入</Link>
        　<Link className="header" to="/CardSellPre">売却</Link>
        　<Link className="header" to="/PackBuy">パック購入</Link>
        　<Link className="header" to="/QuicksightView">Quicksight</Link>
        <div className="clear-element"></div>
      </div>
    )
  }
}

export default Header;
//export default withAuthenticator(Header);